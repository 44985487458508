nav {
  background-color: #333;
  color: #fff;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
  font-family: 'Roboto Slab', serif;
  margin-right: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: red;
  background-color:white;
  padding: 5px
}