.section {
  color: black;
  background-color: white;
  font-family: 'Roboto Slab', serif;
}

.section-link {
    color: red;
}

.project-link{
    color: red;
}

.flex-container {
    display: flex;
}

.flex-item {
    flex: 1
}