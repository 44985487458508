img {
 width: 40%;
 height: 40%;
}

.chase {
    margin-top: 150px;
}

.mastercard {
    margin-left: 150px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set modal height to fill the viewport height */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  text-align: center;
  background-color: black;
  color: white;
  font-family: 'Roboto Slab', serif;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}