
.flag {
  height: 200px;
  width: 200px;
  display: none;
  position: relative;
}

body {
  font-family: sans-serif;
  margin: 0; /* Reset default margin */
}

.bold {
  font-weight: bold;
}

#AmericanFlagGamePage {
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-color: #8a0f29;
}
